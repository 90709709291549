export const colors = [`rgb(255, 144, 232)`,`rgb(255, 201, 0)`,`rgb(255, 112, 81)`,`rgb(62, 207, 193)`,`rgb(144, 168, 237)`];

export const changeBgColor = (el) => {
    const currentBgColor = el.style.backgroundColor;
    let newColor = currentBgColor;
    while (newColor == currentBgColor){
        newColor = colors[Math.floor(Math.random() * colors.length)]
    }
    el.style.backgroundColor = newColor;
    return newColor;
}