import {textsAbout} from "../data/texts"
import { colors,changeBgColor } from "../data/colors";
import SectionTitle from "./SectionTitle";
function About() {

    let leftMargin = 0;
    const isWide = window.innerWidth > 1500;
    return (
      <section id="about">
        <SectionTitle title="ABOUT" />
        {textsAbout.map((text,k) => {
            let angle = 0;
            while (angle === 0) {
              angle = Math.random() * 6 - 3;
            }
            if (k < 6) {
                leftMargin += window.innerWidth * 0.024 * k;
            } else {
                leftMargin -= window.innerWidth * 0.024 * k;
                if (leftMargin < 0) {
                    leftMargin = 0;
                }
            }
            return (
                <p className="sentence" key={k} 
                    style={{backgroundColor: colors[k%colors.length], marginLeft: isWide?`${leftMargin}px`:'5px',transform: `rotate(${angle}deg)`}}
                    onClick={(e) => changeBgColor(e.target)}>
                    {text}
                </p>
            )
        })}
      </section>
    );
  }
  
  export default About;