const Phrase = ({phrase}) => {
    console.log(phrase)
    return(
        <>
            <p className="phrase">{phrase.text}</p>
            <p className="phrase-tooltip">{phrase.tooltip}</p>
        </>
    )
}

export default Phrase;