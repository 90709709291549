const SectionTitle = ({title}) => {
    const chars = Array.from(title);
    return (
        <div className="wavy">
        {chars.map((c,i) => {
            return (
            <span style={{"--i": i}} key={i}>{c}</span>
        )}
        )}
        </div>
    )
}

export default SectionTitle;