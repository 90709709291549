import {footerPhrases} from "../data/texts"
import { useState } from "react"
import Phrase from "./Phrase";
function Footer() {
    const currentPhraseIndex = Math.floor(Math.random() * footerPhrases.length)
    const [phrase,setPhrase] = useState(footerPhrases[currentPhraseIndex]);
    
    const loadPhrase = () => {
        let newPhrase = phrase;
        while(newPhrase.text == phrase.text){
            newPhrase = footerPhrases[Math.floor(Math.random() * footerPhrases.length)]
        }
        setPhrase(newPhrase)
    }

    return (
        <footer>
            <p id="copyright">© 2023 Ioannis Panagiotopoulos</p>
            <div id="phrase-toggle" onClick={loadPhrase}><img src="/images/socrates.png"></img></div>
            {/* {phrase && ( */}
                <Phrase phrase={phrase} />
            {/* )} */}
        </footer>
    );
}

export default Footer;