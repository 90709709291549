export const textsAbout = [
    "I am a polymath that loves experimenting with anything from 3d animation to distributed systems programming. Passionate about technology that transforms our everyday lives, drawn to focus on the web early on.",
    "Nowadays I have immersed myself fully in the web3 ecosystem, which echoes the necessity to align techonology innovation with infrastructure modern society desperately needs.",
    "I wrote my first program back in 2004 in BASIC, at the age of 14. The first language I actually learned was Java in my uni years.",
    "Currently I'm mostly using Javascript & Solidity, learning Go on the side.",
    "I have lived, studied and worked in Athens, Greece for the past 32 years.",
    "My travels have taken me to 13 countries, where I attended 9 conferences and spoken at 2.",
    "Outside CS, I've studied on my own time history & music.",
    "A gamer at heart, retired at semi-pro level from WoW & poker.",
    "I love the outdoors, and sunny days at the beach."
];

export const footerPhrases = [
    {
        text: "Αμ’ έπος αμ’ έργον",
        tooltip: "Alogn with words come deeds (Herodotus)"
    },
    {
        text: "Ανδρών επιφανών πάσα γη τάφος",
        tooltip: "For glorious men, the whole earth is a grave (Pericles)"
    },
    {
        text: "Γνώθι σαυτόν",
        tooltip: "Know thyself"
    },
    {
        text: "Εν οίδα ότι ουδέν οίδα.",
        tooltip: "The only true wisdom is in knowing you know nothing. (Socrates)"
    },
    {
        text: "Έοικα γούν τούτου γε σμικρώ τινι αυτώ τούτω σοφώτερος είναι, ότι α μη οίδα ουδέ οίομαι ειδέναι.",
        tooltip: "I look a little wiser because I don't pretend to know what I don't. (Socrates)"
    },
    {
        text: "Φοβού τους Δαναούς και δώρα φέροντας",
        tooltip: "Beware the Greeks bearing gifts (Virgil)"
    }
    
]