import projectList from "../data/projects";
import { colors , changeBgColor} from "../data/colors";
import SectionTitle from "./SectionTitle";
function Projects() {

    return (
      <section id="projects">
        <SectionTitle title="PROJECTS" />
        <div id="projects-container">
            {projectList.map((project,k) => {
                return (
                <div className="project-card" style={{backgroundColor: colors[k%colors.length]}} key={k} onClick={(e) => changeBgColor(e.target)}>
                    <a href={project.link} target="_blank" className="project-image-container"><img alt={project.name} src={project.image} className="project-image" loading="lazy"></img></a>
                    <a href={project.link} target="_blank" className="project-title"><h3>{project.name}</h3></a>
                    <p className="project-summary">{project.summary}</p>
                    {project.code ? (<a href={project.code} className="repo-link" target="_blank">Code</a>) : ('')}
                </div>
                )
            })}
            {projectList.length%2==1 &&(
                <div className="project-card hide-mobile"></div>
            )}
        </div>
      </section>
    );
  }
  
  export default Projects;