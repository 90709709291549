import SectionTitle from "./SectionTitle";
function Contact() {
    return (
        <section id="contact">
            <SectionTitle title="CONTACT" />
            <p>I am currently working on <a href="https://dripit.io" target="_blank">dripit.io</a>.</p>
            <p id="email">
                For inquiries drop me an email at:<br></br>
                gfkacid@gmail.com
            </p>
            <a id="github" href="https://github.com/gfkacid" target="_blank">Github</a>
            <a id="linkedin" href="https://www.linkedin.com/in/john-panagiotopoulos/" target="_blank">LinkedIn</a>
            <a id="twitter" href="https://twitter.com/gfk_acid" target="_blank">Twitter</a>
        </section>
    );
}

export default Contact;