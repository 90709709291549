import Header from './components/Header';
import Intro from './components/Intro';
import Marquee from "react-fast-marquee";
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Marquee speed={40}>
        <span>WELCOME TO MY PROFILE</span>
        <span>⋄</span>
        <span>THIS IS MY PLAYGROUND</span>
        <span>⋄</span>
        <span>TRY INTERACTING WITH THINGS</span>
        <span>⋄</span>
        <span>CLICK ON ANYTHING COLORFUL</span>
        <span>⋄</span>
        <span>NO AESTHETICS WERE HARMED IN THE MAKING OF THIS WEBSITE</span>
        <span>⋄</span>
        <span>WELCOME TO MY PROFILE</span>
        <span>⋄</span>
        <span>THIS IS MY PLAYGROUND</span>
        <span>⋄</span>
        <span>TRY INTERACTING WITH THINGS</span>
        <span>⋄</span>
        <span>CLICK ON ANYTHING COLORFUL</span>
        <span>⋄</span>
        <span>NO AESTHETICS WERE HARMED IN THE MAKING OF THIS WEBSITE</span>
      </Marquee>
      <Header/>
      <main id="main">
        <Intro/>
        <div id="main-content">
          <Projects/>
          <Contact/>
          <About/>
          {/* <section id="awards"></section> */}
          {/* <section id="this-website"></section> */}
        </div>
      </main>
      <Footer/>
      
    </>
  );
}

export default App;
