import GameOfLife from "./GameOfLife";

function Intro() {
    return (
      <section id="intro">
        <div id="p5_loading" class="loadingclass">
            <div><div>LOADING</div></div>
        </div>
        <GameOfLife/>
        <p className="left">I'm Ioannis Panagiotopoulos, aka 0xAcid, and this is my e-crib 😎</p>
        <p className="right">I'm navigating Life as a Full-stack Software Engineer, tinkering with data and interactive UI, playing my tiny part in shaping the future of the web.</p>
      </section>
    );
  }
  
  export default Intro;