const projectList = [
    {
        name: "ActionForge",
        image: "/images/actionforge.png",
        tags: ["DAO","Automation","Snapshot","ETHIstanbul'23","Chainlink","Web3"],
        summary: "Automate transactions onchain depending on outcome of any Snapshot proposal. Submission for ETHIstanbul 2023 Hackathon, Chainlink Services 1st prize winner",
        code: "https://github.com/action-forge/protocol",
        link: "https://actionforge.xyz/",
    },
    {
        name: "Dripit",
        image: "/images/dripit_poc.png",
        tags: ["Music","NFT","ETHOnline'23","Web3"],
        summary: "Tokenizing music streaming revenue onchain. Submission for ETHOnline 2023 Hackathon, Safe{AA} 1st prize winner",
        code: "https://github.com/gfkacid/dripit-poc",
        link: "https://dripit-front.vercel.app/",
    },
    {
        name: "Lens Network Graph",
        image: "/images/LensNetworkGraph.png",
        tags: ["Lens","Social","Graph","Web3"],
        summary: "Visual representation of Lens social graph for given handle.",
        code: "https://github.com/gfkacid/lens-network-graph",
        link: "https://lens-network-graph.vercel.app/",
    },
    {
        name: "$BTC.b Dashboard",
        image: "/images/btcb_dune.jpeg",
        tags: ["Data Analysis","Dune","Web3"],
        summary: "Dune dashboard that covers $BTC.b on Avalanche.",
        code: "https://dune.com/queries/1656774/2752325",
        link: "https://dune.com/gfkacid/btcb-avalanche-bridge",
    },
    {
        name: "Synesthesia",
        image: "/images/synesthesia.gif",
        tags: ["NFT","Web3"],
        summary: "Mixed media interactive NFT collection.",
        code: "",
        link: "https://synesthesia.nftartgreece.com",
    },
    {
        name: "AvaXmas",
        image: "/images/avaxmas.gif",
        tags: ["NFTs","Animation","Web3"],
        summary: "A game of Secret Santa for the Avalanche NFT community; put an NFT in Santa's sock and get a random one back on New Years Eve.",
        code: "https://github.com/gfkacid/avaxmas",
        link: "https://avaxmas.xyz",
    },{
        name: "AdSpaces",
        image: "/images/adspaces.png",
        tags: ["ETHOnline'22","NFTs","Data Analysis","Web3"],
        summary: "A crypto-native p2p marketplace for ad banners. Submission for ETHOnline 2022 Hackathon.",
        code: "https://github.com/gfkacid/adspaces",
        link: "https://adspaces.online",
    },
    {
        name: "Avaboard",
        image: "/images/avaboard.png",
        tags: ["Hackmoney'22","NFTs","Data Analysis","Web3"],
        summary: "Avalanche NFT collection Decentralization/Market Activity score, plotted as points on 2d chart. Submission for Hackmoney 2022 Hackathon.",
        code: "https://github.com/gfkacid/avaboard",
        link: "https://ethglobal.com/showcase/avaboard-nft-ysku7",
    },
    {
        name: "NFT Art Greece",
        image: "/images/nftartgreece.gif",
        tags: ["NFT","Web3"],
        summary: "Home of the biggest Greek NFT community. Helping artists tap into web3 and mint their art.",
        code: "",
        link: "https://nftartgreece.com",
    },
    {
        name: "Orca",
        image: "/images/orca.svg",
        tags: ["Data Analysis","Web2"],
        summary: "A one-stop-shop for maritime data collection & analysis.",
        code: "",
        link: "https://orca.tools",
    },
]

export default projectList;